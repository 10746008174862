<template lang="pug">
		.container
				.gap-top-login
						el-card.box-card.login-container-form
								.clearfix.d-flex.justify-content-between(slot="header")
										el-col(:span="24")
												h2.header-text ZGP Services - SearchBox
								el-form(ref="form" label-width='120px', :model="form" element-loading-text='Loading' @keyup.enter.native="onSubmit")

										el-row.inner-form()

												el-col(:span="24")
														el-form-item(label-position="top" label-width="auto" :error="error.username.init ? error.username.msg : ''")
																el-input(type="text", v-model="form.username", placeholder="Username" )
												el-col(:span="24")
														el-form-item(label-position="top" label-width="auto" :error="error.password.init ? error.password.msg : ''")
																el-input(type="password" v-model="form.password", placeholder="Password" show-password="")
																	span.show-pwd(@click='showPwd')
																	svg-icon(:icon-class="passwordType === 'password' ? 'eye' : 'eye-open'")
												el-col(v-if="showOtpInput" :span="24")
														el-form-item(label="Authenticator OTP" label-position="top" label-width="auto" :error="error.password.init ? error.password.msg : ''")
																el-input(type="password" v-model="otpForm.otp", placeholder="6-digit code in Authenticator app" :maxlength="6" show-password="")
												el-col(:span="24")
														el-button.mt-2.w-100(type="primary"  @click="onSubmit" :disabled="showOtpInput && otpForm.otp.length < 6") Login
												el-col(:span="24")
														small.text-red

</template>

<style lang="scss">
$text-color: #606266;
.container {
		.gap-top-login {
				padding-top: 10rem;
		}

		.login-container-form {
				margin-top: 10rem;
				width: 500px;
				margin: 0 auto;
				overflow: hidden;
				position: relative;

				@media only screen and (max-width: 767px) {
						width: 95%;
				}

				.header-text {
						font-weight: 600;
						color: $text-color;
						@media only screen and (max-width: 767px) {
								font-size: 24px;
						}
				}

				.inner-form {
						padding: 2rem;
						@media only screen and (max-width: 767px) {
								padding: 1rem;
						}
						/*border: 1px solid #909398;*/
						border-radius: 0.7rem;
				}

				.el-form-item__content {
						width: auto;
				}
		}
}
</style>

<script lang="js">
import validator from 'validator';
import Vue from 'vue';

export default {
		data() {
				return {
						showPwd: false,
						passwordType: 'password',
						showOtpInput: false,
						busy: false,
						otpForm: {
								otp: ''
						},
						form: {
								username: '',
								password: ''
						},
						error: {
								otp: {init: false, msg: ''},
								username: {init: false, msg: ''},
								password: {init: false, msg: ''},
						}
				}
		},
		computed: {
				appVer() {
						return this.$store.state.PACKAGE_VERSION
				}
		},
		methods: {
				validate() {
						let error = false;

						this.error = {username: {init: false, msg: ''}, password: {init: false, msg: ''}};

						if (!this.form.username || this.form.username === '') {
								error = true;
								this.error.username.init = true;
								this.error.username.msg = 'Username is required';
						} else if (!validator.isAlphanumeric(this.form.username)) {
								error = true;
								this.error.username.init = true;
								this.error.username.msg = 'Username must be alpha numeric';
						}

						if (!this.form.password || this.form.password === '') {
								error = true;
								this.error.password.init = true;
								this.error.password.msg = 'Password is required';
						} else if (this.form.password.length < 6) {
								error = true;
								this.error.password.init = true;
								this.error.password.msg = 'Password must be at least 6 characters';
						}

						return !error;
				},
				onSubmit() {
						if (!this.validate()) return;
						this.busy = true;
						let otp = (this.showOtpInput) ? {otp: this.otpForm.otp} : {};

						this.$apix.sendHttpRequest('POST', 'auth/login', {
								username: this.form.username,
								password: this.form.password,
								...otp
						})
								.then(res => {
										if (res.tfa_flag) this.showOtpInput = true;
										else if (!!res.token) {
												Vue.http.headers.common['Authorization'] = 'Bearer ' + res.token;
												this.$store.dispatch('login', res);
												window.location.href = '/search'
										} else {
												this.$notify.error('Invalid username or password');
										}
								})
								.catch(err => {
										console.error('[ERROR] Authentication failed: ', err);
								});
				},

				otp() {

				}
		}
}
</script>
